import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Img from "gatsby-image/withIEPolyfill"

const TextCol = ({ data }) => (
  <Col className={"text-col"} lg={{ span: 6, order: data.reverse ? 2 : 1 }} md={{ span: 12, order: 2 }}
       sm={{ span: 12, order: 2 }} xs={{ span: 12, order: 2 }}>
    {data.children}
  </Col>
)

const TextWithImage = props => (
  <Container
    className={props.additionalClassName ? "text-image-section " + props.additionalClassName : "text-image-section"}>
    <Row>
      {!props.reverse &&
      <TextCol data={props}/>
      }

      <Col className={"image-col"} lg={{ span: 6, order: props.reverse ? 1 : 2 }} md={{ span: 12, order: 1 }}
           sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}>
        <Img fixed={props.image} alt={props.alt} className={"image-wrapper"}
             style={{ display: "block", width: "auto", height: "100%" }}
             objectPosition={props.objectPosition || "50% 50%"}/>
      </Col>

      {props.reverse &&
      <TextCol data={props}/>
      }
    </Row>
  </Container>
)

export default TextWithImage
