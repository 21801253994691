import React from "react"

import Layout from "../components/layout"
import Meta from "../components/meta"
import TextWithImage from "../components/text-with-image"
import { graphql } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"

export default ({ data }) => (
  <Layout>
    <Meta title={"Coaching im Einklang mit Karate"}
          description={"Das Dorakami Coaching-Angebot umfasst Business- und Einzelcoaching in Kombination mit Elementen der Kampfkunst sowie Workshops & Trainings."}/>
    <div className={"leistungen-page"}>
      <Container className={"section"}>
        <h1>Coaching im Einklang mit Karate</h1>
        <p>
          Mein Anspruch an das erfolgreiche Coaching beginnt mit der individuellen Auftragsklärung. Dabei erarbeite ich
          die angestrebte Lösung gemeinsam mit dem Klienten (m/w/d) und wir fixieren das Ergebnis schriftlich.
        </p>
        <p>
          Der Coachingprozess wird stets individuell und effizient gestaltet und umfasst beispielsweise <strong>Bestandteile
          aus dem Mentaltraining, der Resilienz, dem Hypnocoaching</strong> und insbesondere der <strong>Synergie
          zwischen der Kampfkunst sowie der verbalen Kommunikation</strong>. <strong>Körpersprachliche
          Elemente</strong> runden das Angebot ab. Dies bezieht sich auf alle Bereiche des Team-Coaching,
          Führungskräfte-Coaching und Einzelcoaching sowohl im Business- als auch im persönlichen Kontext.<br/>
          Workshops können individuell innerhalb meines Portfolios ausgearbeitet werden. Impulsvorträge und ein- bis
          mehrtägige Workshops, Seminare und Trainings gestalte ich nach Ihren Vorstellungen.
        </p>
      </Container>

      <div className={"odd-section angled-top-bottom"}>
        <TextWithImage additionalClassName={"odd-section-inner"}
                       image={data.meisterhaftInFuehrungImage.childImageSharp.fixed}
                       objectPosition={"50% 60%"} alt={"Motivation im Coaching"} reverse={false}>
          <h2>Meisterhaft in Führung</h2>
          <p>
            Aus der beruflichen Erfahrung als Geschäftsführer eines mittelständischen Unternehmens, der zahlreichen
            Fortbildungen und Erfahrungen im Bereich der Persönlichkeitsentwicklung und meiner Leidenschaft, der
            Kampfkunst, haben sich nach intensiver Beschäftigung mit diesen Themenfeldern hilfreiche Synergien
            herauskristallisiert. Diese positiven Wechselwirkungen ermöglichen es, die Macht des Wortes zu erfahren.
          </p>
          <p>
            Für dieses Erlebnis bietet sich insbesondere das Seminar <strong>Meisterhaft in Führung</strong> an. Dieses
            ist sowohl als Teambildungsmaßnahme als auch im Einzelcoaching durchführbar. Jeweils als Leadershiptool und
            individuell anpassbar, ohne notwendige Vorkenntnisse.
          </p>
          <p>
            Outdoorübungen vermitteln ein Gefühl für die Eigenwahrnehmung und Fremdwahrnehmung. Der Seminarteil
            umfasst das Karate Dô, Eigenwahrnehmung und Fremdwahrnehmung und Elemente der Achtsamkeit. Zusätzlich wird
            die Brücke zum Mentaltraining und zur Kommunikation etabliert, insbesondere zum Training der Resilienz
            innerhalb einer Gruppe. Theoretische Anteile sind stets mit Übungen zur Vertiefung und zum besseren
            individuellen Verständnis verknüpft.
          </p>
        </TextWithImage>
      </div>

      <TextWithImage additionalClassName={"section"} image={data.einzelcoachingImage.childImageSharp.fixed}
                     alt={"Einzelcoaching"} reverse={true}>
        <h2>Einzelcoaching</h2>
        <p>
          Das Wort "Coach" kommt ursprünglich aus dem Englischen und bedeutet im Deutschen "Kutsche". Somit ist ein
          Coach der- oder diejenige, der den Klienten (m/w/d) bei der Entwicklung von Lösungen begleitet. Der Coach
          sitzt sinnbildlich neben dem Klienten auf dem Kutschbock. Ein Coaching basiert auf einer individuellen
          Auftragsklärung und eben dieser Entwicklung von Lösungen beziehungsweise Strategien, die über aktuelle Themen
          im persönlichen Bereich des Klienten, über dessen berufliches Umfeld bis hin zu Zieldefinitionen in der
          Zukunft liegen.
        </p>
        <p>
          Ich bediene mich dabei verschiedener Methoden aus den Bereichen der neurolinguistischen Programmierung (NLP)
          zur Persönlichkeitsentwicklung, dem Mentaltraining und dem Hypnochoaching.
        </p>
      </TextWithImage>

      <div className={"odd-section angled-top-bottom other-offers"}>
        <Container className={"odd-section-inner"}>
          <h2>Nichts für Sie dabei?</h2>
          <p>Zusätzlich zu den oben aufgeführten Angeboten biete ich Ihnen meine Erfahrungen in der Form von Workshops
            und
            Trainings, Impulsvorträgen, Gruppenschulungen und Einzelschulungen. Sprechen Sie mich an!</p>
          <Row>
            <Col md={6}>
              <h3>Workshops</h3>
              <ul className={"bullet-list"}>
                <li>Eigenwahrnehmung und Fremdwahrnehmung</li>
                <li>
                  Führen mit Elementen aus der Kampfkunst<br/>
                  (beispielsweise "Meisterhaft in Führung")
                </li>
                <li>Teambuilding und Teamorganisation</li>
              </ul>
            </Col>
            <Col md={6}>
              <h3>Gruppenschulungen</h3>
              <ul className={"bullet-list"}>
                <li>Wertschätzender Umgang innerhalb des Teams</li>
                <li>Kommunikation und Unternehmenskultur</li>
                <li>Themen aus dem Führungskräfte-Kontext</li>
                <li>Alles, was Sie in Ihrer Organisation nach vorne bringt!</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3>Impulsvorträge</h3>
              <ul className={"bullet-list"}>
                <li>Synergie zwischen Kampfkunst und Kommunikation</li>
                <li>Zielgerichtete Kommunikation</li>
                <li>Führungsrelevante Themen</li>
                <li>Themen, die Sie interessieren!</li>
              </ul>
            </Col>
            <Col md={6}>
              <h3>Einzelschulungen</h3>
              <ul className={"bullet-list"}>
                <li>"Plötzlich Vorgesetzter"</li>
                <li>Zeitgemäße Personalführung</li>
                <li>Persönliche Zieldefinition</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    meisterhaftInFuehrungImage: file(relativePath: { eq: "meisterhaft-in-fuehrung.jpg" }) {
      childImageSharp {
        fixed(width: 540, height: 650, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    einzelcoachingImage: file(relativePath: { eq: "einzelcoaching.jpg" }) {
      childImageSharp {
        fixed(width: 540, height: 540, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
